import React from "react"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
import Footer from '../components/Footer'
import { MDXRenderer } from "gatsby-plugin-mdx"
import Img from "gatsby-image"
import BackgroundImage from 'gatsby-background-image'


const MDXPostTemplate = ({ data }) => {
  const postData = data.mdx
  let featuredImgFluid = postData.frontmatter.featuredImage.childImageSharp.fluid




  // if (postData.featured_media && postData.featured_media.localFile) {
  //   console.log(postData.featured_media.localFile)
  //   featuredImg = postData.featured_media.localFile.childImageSharp.fixed
  // }

  return (
    <Layout>
      <div id="#md-blog-wrapper">
        <BackgroundImage
          Tag="section"
          fluid={featuredImgFluid}>
          {/* <Img fluid={featuredImgFluid} className="featured-image" /> */}
          <header id="header">
            <Link to="/" >
              <div className="logo">
                {/* <span className="icon fa-skull-crossbones"></span> */}
              </div></Link>
          </header>
        </BackgroundImage>
        <h3 style={{ fontSize: 33, marginTop: 0 }} dangerouslySetInnerHTML={{ __html: postData.frontmatter.title }} />
        <MDXRenderer title="My Stuff!">{postData.body}</MDXRenderer>
        <Link to={"/blog/1"}><p>back to blog</p></Link>
        <Footer />
      </div>
    </Layout>
  )
}
export const query = graphql`
query($blog_id: String){
  mdx(id: {eq: $blog_id}) {
    body
    slug
    id
    frontmatter {
      published
      title
      date(formatString: "DD/MM/YYY")
      featuredImage {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    wordCount {
      words
      sentences
      paragraphs
    }
  }
}`

export default MDXPostTemplate
